import { Layout, AppBar, Button, useRefresh} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import image from './assets/image/Ingesan_NEG.png';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router';

const MyAppBar = (props) => {
    const refresh = useRefresh();
    const location = useLocation();

    const [isVisible, setIsVisible] = useState(false);


    
    useEffect(() => {
        const button = document.querySelector('header button[aria-label="Refrescar"]');

        //console.log('URL:', location.pathname);

        if (!button) return;


        if (location.pathname.includes('calendar')) {
            button.style.display = 'none'; // Ocultar el botón
            setIsVisible(true);
        } else if (location.pathname.includes('home')) {
            button.style.display = 'none'; // Ocultar el botón
            setIsVisible(true);
        } else {
            button.style.display = ''; // Mostrar el botón
            setIsVisible(false);
        }
        
        // eslint-disable-next-line
    }, [location.pathname]); // Dependencia reactiva



    const handleCustomRefresh = () => {
        refresh();
        window.location.reload(); // Recarga la página completamente
        
    };


    return (
        <AppBar {...props}>
            <img src={image} alt="logo" height={48} style={{ marginRight: 10 }} />

            <Box flex="1">
                <Typography variant="h6" id="react-admin-title"></Typography>
            </Box>

            {
                isVisible ?
                <Button
                style={{fontSize: '0.7rem', color: 'white', textTransform: 'none'}}
                onClick={handleCustomRefresh}
                //label={urlName}
                startIcon={<RefreshIcon  style={{color: "white", fontSize: "1.5rem"}}/>}
                />:null
            }
           
        </AppBar>
    );
};

export const MyLayout = (props) => <Layout {...props} appBar={MyAppBar}  />;
