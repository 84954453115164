import Box from '@material-ui/core/Box';
import { Card, CardMedia, CardContent, CardActions, Button, CardHeader, Avatar, Tooltip, Typography, Grid } from '@material-ui/core';
import { useGetIdentity } from "ra-core";
import { SmallCalendarCustom } from "../components/Calendar/SmallCalendar";
import { useEffect, useState } from "react";
import ProfessionalProvider from "../provider/professional.provider";
import scheduledActivitiesProvider from "../provider/scheduledActivities.provider";
import userProvider from "../provider/user.provider";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { SSEEvent } from "../services/ohl.virtualCenter.api";
import { notify as sseNotify } from "../services/sse.service";
import { makeStyles } from '@material-ui/core/styles';

import {
    ShowButton
} from "ra-ui-materialui";
import InputIcon from '@material-ui/icons/Input';
import MoreIcon from '@material-ui/icons/More';

import GroupImagen from "../assets/image/Group.jpg"
import SingleImagen from "../assets/image/Single.jpg"

export const HomeList = props => {

    const [scheduledActivities, setScheduledActivities] = useState([])
    const [serviceLoading, setServiceLoading] = useState(true)
    const { identity, loading } = useGetIdentity();
    const history = useHistory()


    const agendaHandler = () => {
        history?.push(`/calendar`);
    };
    
    useEffect(() => {
        scheduledActivitiesProvider.getList(null, {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'StartDate', order: 'ASC' },
            filter: {
                isRecord: false,
                professionalId: props?.id === undefined ? identity?.professionalId : undefined,
                StartDateFrom: moment().format('yyyy-MM-DDT00:00:00'),
                startDateTo: moment().add(0, "days").format('yyyy-MM-DDT00:00:00')
            }
        }).then(resp => {
            setScheduledActivities(resp.data)
        }).finally(() => {
            setServiceLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="dashboardContainer">
            <div className="dashboardHeaderContainer">
                <ProfileView identity={identity} />
            </div>
            <div className="dashboardBodyContainer">
                <div className="sessionsGridContainer">
                    <div className='divCustom'>
                        <Typography style={{ fontWeight: 'bold' }} variant="h8">
                            Tus sesiones de hoy ({moment().format("DD MMM YYYY")})
                        </Typography>
                        <div className='gridBoxBody'>
                            <TodaySessions serviceLoading={serviceLoading} scheduledActivities={scheduledActivities} loading={loading} />
                        </div>
                    </div>
                    <div className='divCalendar'>
                        <Typography style={{ fontWeight: 'bold' }} variant="h8">
                            Resumen de Usuarios
                        </Typography>
                        <div className='gridBoxBody'>
                            <UserSummary title="Usuarios activos" active="ACTIVE" />
                            <UserSummary title="Usuarios inactivos" active="INACTIVE" />
                            <UserSummary title="Usuarios de baja" active="LEAVE" />
                        </div>
                    </div>
                    <div className='divCalendar'>
                        <Typography className='agenda-typograhy' onClick={agendaHandler} style={{ fontWeight: 'bold' }} variant="h8">
                            Agenda
                        </Typography>
                        <div style={{ marginTop: 10, height: '100%' }}>
                            <SmallCalendarCustom {...props} identity={identity} isNextMonth={false} weightCustom={'20rem'} />
                        </div>
                    </div>
                </div>
                <ActivitiesGrid history={history} serviceLoading={serviceLoading} scheduledActivities={scheduledActivities} loading={loading}></ActivitiesGrid>
            </div>
        </div>
    )
}

const TodaySessions = (props) => {
    const { serviceLoading, scheduledActivities, loading } = props;
    if (loading || serviceLoading) return <div>Cargando...</div>

    return !scheduledActivities?.length ? <div>No hay actividades para hoy</div> :
    scheduledActivities.map((sc, index) => (
        <SessionNowComponent
          key={index}
          session={`${moment(sc?.startDate).format("HH:mm")} - ${sc?.activity?.primaryTypeDescription} - ${
            sc?.activity?.primaryTypeDescription === 'Grupal'
              ? sc?.activity?.secondaryTypeDescription
              : sc?.activity?.secondaryTypeDescription + " - " + (sc?.participants?.[0]?.alias || sc?.participants?.[0]?.name || "")
          }`}
        />
      ))
      
}

const UserSummary = (props) => {
    const [total, setTotal] = useState('Cargando...')
    useEffect(() => {
        userProvider.getList(null, {
            pagination: { page: 1, perPage: 1 },
            sort: {},
            filter: {
                isMapperAll: false,
                active: props.active,
            }
        }).then(resp => {
            setTotal(resp.total)
        })
        // eslint-disable-next-line
    }, [])

    return <div> <span className='description-typograhy'>{props.title}: &nbsp; </span><span style={{fontWeight:'bold'}}>{total}</span></div>
}


export const ProfileView = (props) => {
    const [profile, setProfile] = useState()
    const { identity } = props;
    const [identificationNumber, setIdentificationNumber] = useState()
    useEffect(() => {
        if (identity && identity.identificationNumber) {
            setIdentificationNumber(identity.identificationNumber);
        }
    }, [identity]);

    useEffect(() => {

        const fetchProfile = async () => {

            ProfessionalProvider.getProfile('', { identificationNumber: identificationNumber })
                .then((res) => {
                    setProfile(res?.src)
                })
                .catch(
                    (error) => {
                        console.log(error);
                    }
                );

        };

        if (identificationNumber) fetchProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identificationNumber]);


    return (
        <Box
            sx={{
                backgroundColor: '#6ecfc6', // Color verde de fondo
                padding: '30px',
                borderRadius: '20px',
                marginBottom: '20px',
                textAlign: 'left',  // Texto alineado a la izquierda
                position: 'relative'
            }}
        >

            {/* Mensaje de bienvenida */}
            <Typography className='typographyProfile' variant="h4" gutterBottom sx={{ marginTop: '20px' }}>
                ¡Bienvenido/a, {identity?.name}!
            </Typography>

            {/* Tarjeta con información del usuario */}
            <Card className='cardProfile'>
                <CardHeader
                    classes={{ subheader: 'customSubheader' }}  // Aplica la clase CSS al subheader
                    avatar={
                        <Avatar
                            className='avatarProfile'
                            alt={identity?.fullName}
                            src={profile}
                        />
                    }
                    title={identity?.fullName}
                    subheader={identity?.email}
                    sx={{ textAlign: 'left' }}  // Alineamos el contenido a la izquierda
                    titleTypographyProps={{
                        style: { fontWeight: 'bold', fontSize: 16 }
                    }}
                />
            </Card>
        </Box>
    );
};


const ActivityCard = (props) => {

    const { history, scheduledActivity } = props;


    const title = scheduledActivity?.activity?.contracts[0]?.contractNumber;
    const idActivity = scheduledActivity?.activity?.id;
    const idContract = scheduledActivity?.activity?.contracts[0]?.id;
    const type = scheduledActivity?.activity?.primaryTypeDescription;

    const session = `${moment(scheduledActivity?.startDate).format("HH:mm")} - ${scheduledActivity?.activity?.primaryTypeDescription} - ${type === 'Grupal' ? scheduledActivity?.activity?.secondaryTypeDescription : scheduledActivity?.activity?.secondaryTypeDescription + " - " + (scheduledActivity?.participants[0]?.alias || scheduledActivity?.participants[0]?.name)
        }`;


    const detailHandler = () => {
        history?.push(`/activities/${idActivity}`);
    };


    const SendDoPolling = () => {
        scheduledActivity?.participants?.forEach(p => {
            sseNotify(new SSEEvent({ clientId: p.id + "", jitsiRoom: "Landing", type: 'DoPolling' }))
        })
    }


    return (
        <Card className="card">
            <div className='div-img'>
                <CardMedia
                    component="img"
                    alt={title}
                    className="card-media"
                    image={type === 'Grupal' ? GroupImagen : SingleImagen}
                    title={title}
                />
            </div>

            <CardContent className="card-content">
                <Typography component="span">
                    <span className='contract-Typograhy' >Contrato #{idContract}</span>
                    {
                        scheduledActivity?.activity?.contracts?.length > 1 && (
                            <span style={{fontSize: '12px', marginLeft: '10px'}} >Varios contratos <MoreIcon style={{verticalAlign: 'top', color: '#00ab8e'}}/></span>
                        )
                    }
                </Typography>
                <CardActions className="card-actions-activity">
                    <TitleComponent title={title} />
                    <Button className='font-zise-rem' style={{ minWidth: 'fit-content' }} variant="outlined" color="primary" onClick={detailHandler} >Ver detalle</Button>
                </CardActions>
                <SessionComponent session={session} />
            </CardContent>
            <CardActions>
                {
                    !scheduledActivity?.isHeld ?
                        <div className="card-actions">
                            <Button variant="outlined" color="primary" onClick={SendDoPolling}>Actualizar agenda</Button>
                            <ShowButton className='font-zise-rem' variant="outlined" color="primary" basePath="/scheduledActivities" record={scheduledActivity} label="Iniciar Actividad" icon={false} />
                        </div>
                        :
                        scheduledActivity?.realEndDate ? <span className='span-finally-activity'>Actividad Finalizada</span> :
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <ShowButton className='font-zise-rem' variant="outlined" color="primary" basePath="/scheduledActivities" record={scheduledActivity} label="Entrar" icon={<InputIcon />} />
                            </div>
                }

            </CardActions>
        </Card>
    );
};


const TitleComponent = ({ title = '' }) => {
    const truncatedTitle = title.length > 25 ? `${title.substring(0, 25)}...` : title;
    const classes = useStyles();

    return (
        <Tooltip title={title} classes={{ tooltip: classes.customTooltip }}>
            <span className='spanTitle'>
                {truncatedTitle}
            </span>
        </Tooltip>
    );
};


const SessionComponent = ({ session }) => {
    const truncatedSession = session.length > 40 ? `${session.substring(0, 40)}...` : session;
    const classes = useStyles();

    return (
        <Tooltip title={session} classes={{ tooltip: classes.customTooltip }}>
            <Typography className='description-typograhy' variant="body2">{truncatedSession}</Typography>
        </Tooltip>
    );
};


const useStyles = makeStyles({
    customTooltip: {
      fontSize: '0.88rem', 
    },
  });

const SessionNowComponent = ({ session }) => {
    const maxLength = 45;
    const truncatedSession = session.length > maxLength ? `${session.substring(0, maxLength)}...` : session;
    const classes = useStyles();

    return (
        session.length > maxLength ? 
        <Tooltip title={session} classes={{ tooltip: classes.customTooltip }}>
            <div className='description-typograhy'>{truncatedSession}</div>
        </Tooltip>:
         <div className='description-typograhy'>{truncatedSession}</div>
    );
};

export const ActivitiesGrid = (props) => {

    const { history, serviceLoading, scheduledActivities, loading } = props;

    const newActivityHandler = () => {
        history.push('/activities/create')
    };

    if (loading || serviceLoading) return <div>Cargando...</div>

    return (

        <div className='grid-activity' >

            <Typography style={{ fontWeight: 'bold' }} variant="h8">
                Actividad
            </Typography>
            {
                !scheduledActivities?.length ?
                    <div style={{ width: '70%' }}>
                        <br />
                        <br />
                        <Typography variant="h7">
                            No hay actividades para hoy
                        </Typography>
                    </div>
                    :

                    <Grid className='grid-card-activity-left' container spacing={2}>

                        {scheduledActivities.map((scheduledActivity, index) => (
                            <Grid key={index} style={{ width: 'max-content' }} item md={4}>
                                <ActivityCard
                                    history={history}
                                    scheduledActivity={scheduledActivity}
                                />
                            </Grid>
                        ))}
                    </Grid>

            }

            <Grid className='grid-card-activity-right' container spacing={3}>
                <Grid className='grid-new-activity' item xs={12} sm={6} >
                    <Card className="new-activity" onClick={newActivityHandler} >
                        <CardContent>
                            <span>+</span>
                            <Typography style={{ fontSize: "1rem" }} align="center">Crear una nueva Actividad</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </div>

    );
};