import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { RecordForm } from "../resources/records";
import { Create, Edit } from 'react-admin';
import { useLocation } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const RecordsFormDialog = (props) => {
    const location = useLocation();

    const handleCloseClick = () => {
        props.onClose();
    };

    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={handleCloseClick}
        >
           
            {
                <MuiDialogTitle>
                    {props?.mode === 'create'?'Crear Registro': 'Editar Registro'}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseClick}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
            }
            <DialogContent
            style={{height: '25rem'}}>

                {props.mode==='create'? 
                <Create resource="records" basePath={location?.pathname} >
                    {RecordForm(props, props.mode)}

                </Create>
                : 
                <Edit {...props} mutationMode="pessimistic" resource="records" id={props.id} basePath={location?.pathname} >
                    {RecordForm(props, props.mode)}

                </Edit>
            }

            </DialogContent>
        </Dialog>
    )
}