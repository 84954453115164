import { email, maxValue, number, required, useMutation, useNotify, useRedirect, useGetIdentity } from "ra-core";
import { ArrayInput, SimpleFormIterator, ReferenceInput, Datagrid, List, TextField, EmailField, Show, Edit, TextInput, TabbedForm, FormTab, Create, Filter, CheckboxGroupInput, SelectInput, AutocompleteInput, AutocompleteArrayInput, DateInput, FunctionField } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { ChipArrayUseStyles, TabsUseStyles } from "../App";
import { getLanguajes, getProfessionalRoles, getProfessionalType, getWorkingCenter } from "../services/master-tables.service";
import { PostBulkActionButtonsPermision, CustomToolbarPermissions } from "./utils";
import AutoCompleteCustom from "../components/AutoCompleteCustom"

import { LanguajeArrayField } from "../components/Fields/LanguajeArrayField"
import { WorkingCenterField } from "../components/Fields/WorkingCenterField";
import { ProfessionalTypeField } from "../components/Fields/ProfessionalTypeField";
import { useTranslate, ImageInput, ImageField } from "react-admin";
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';
// import jsPDF from 'jspdf'
// // eslint-disable-next-line
// import autoTable from 'jspdf-autotable';
import jsonExport from 'jsonexport/dist';
import { downloadCSV, FormDataConsumer } from "react-admin";
import MUIButton from '@material-ui/core/Button';
import { useFormState, useForm } from 'react-final-form';
import './resources.css';
import {BooleanInputCustom} from '../components/BooleanInputCustom';
import RegistroIcon from '@material-ui/icons/EventBusy';
import PostIcon from '@material-ui/icons/Book';
import { useHistory } from "react-router-dom";

import { BigCalendarCustom } from "../components/Calendar/BigCalendar";
import { SmallCalendarCustom } from "../components/Calendar/SmallCalendar";
import moment from 'moment';
import { RecordsFormDialog } from "../components/RecordsFormDialog";

var XLSX = require("xlsx");

const validateProfessionalCreation = (values) => {
    const errors = {};
    if (!values.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values.identificationNumber && !validarDNIoNIE(values.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values.surnames?.trim()) {
        errors.surnames = 'Requerido';
    }
    if (!values.password) {
        errors.password = 'Requerido';
    }
    if (!values.professionalTypeId) {
        errors.professionalTypeId = 'Requerido';
    }
    if (!values.email?.trim()) {
        errors.email = 'Requerido';
    }
    if (!values.activeDate) {
        errors.activeDate = 'Requerido';
    }
    if (!values?.contracts?.length) {
        errors.contracts = 'Requerido';
        errors.contractsValidation = 'Requerido';
    }
    if (values.leavingDate && values.activeDate && moment(values.leavingDate).isBefore(values.activeDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de alta';
    }
    if(values?.contracts?.length && !values?.contracts?.some(c => c?.isMain)){
        errors.contracts = values.contracts.map((contract, index) => ({
            ...errors.contracts?.[index],
            isMain: 'Seleccione un contrato principal',
          }));
        errors.mainContractId = 'Requerido';
        errors.contractsValidation = 'Requerido';
    }
    return errors
};

const validarDNIoNIE = (dni) => {
    var numero, letr, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        } else {
            //alert('Dni correcto');
            return true;
        }
    } else {
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

const validateProfessionalEdit = (values) => {
    const errors = {};
    if (!values.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values.identificationNumber && !validarDNIoNIE(values.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values.surnames?.trim()) {
        errors.surnames = 'Requerido';
    }
    if (!values.professionalTypeId) {
        errors.professionalTypeId = 'Requerido';
    }
    if (!values.email?.trim()) {
        errors.email = 'Requerido';
    }
    if (!values.activeDate) {
        errors.activeDate = 'Requerido';
    }
    if (!values?.contracts?.length) {
        errors.contracts = 'Requerido';
        errors.contractsValidation = 'Requerido';
    }
    if (values.leavingDate && values.activeDate && moment(values.leavingDate).isBefore(values.activeDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de alta';
    }
    if(values?.contracts?.length && !values?.contracts?.some(c => c?.isMain)){
        errors.contracts = values.contracts.map((contract, index) => ({
            ...errors.contracts?.[index],
            isMain: 'Seleccione un contrato principal',
          }));
        errors.mainContractId = 'requerido';
        errors.contractsValidation = 'Requerido';
    }
    return errors
};

const ProfessionalTitle = ({ record }) => {
    return <span>Profesional {record ? `${record.name}` : ''}</span>;
};

const ProfessionalFilter = (props) => {
    const [workingCenter, setworkingCenter] = useState()
    const [professionalType, setprofessionalType] = useState()

    const translate = useTranslate();

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalType(res.elements.filter(e => e.value !== 'ADMIN'))
            }
        )
    }, []);

    return (
        <Filter {...props} style={{ "marginTop": 0 + "px" }}>
            <TextInput label="NIF/NIE" source="identificationNumber" alwaysOn />
            <TextInput label="Nombre" source="name" alwaysOn />
            <SelectInput label="Estado" source="active" alwaysOn choices={[
                { id: 'all', name: 'Todos' },
                { id: 'true', name: 'Activo' },
                { id: 'false', name: 'No Activo' },
            ]} />
            <ReferenceInput
                    label="Contrato Principal"
                    source="contractMainId"
                    reference="contracts"
                    perPage={100}
                    resettable={true}
                    filterToQuery={(value) => {
                        return { contractNumber: value, isValidity: false }
                    }}
                >
                    <AutocompleteInput  optionText={(record) => record?.contractNumber}/>
            </ReferenceInput>
            <ReferenceInput
                label="Contratos"
                source="contractId"
                reference="contracts"
                perPage={100}
                resettable={true}
                filterToQuery={(value) => {
                    return { contractNumber: value, isValidity: false }
                }}
            >
                <AutocompleteInput optionText={(record) => record?.contractNumber} />
            </ReferenceInput>
            {
                workingCenter ?
                    <AutocompleteInput
                        label="Centro de Trabajo"
                        source="associatedWorkingCenterId"
                        choices={workingCenter}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            <CheckboxGroupInput label="Tipo" source="professionalTypeId" alwaysOn
                optionText={choice => translate('professionalTypeId.' + choice.value)}
                optionValue="id"
                choices={professionalType}
            />
            
            
        </Filter>
    )
};

export const ProfessionalList = props => {
    const [professionalTypes, setprofessionalTypes] = useState()
    const [languageList, setLanguageList] = useState();
    const [workingCenterList, setWorkingCenterList] = useState();
    const translate = useTranslate();

    const useStyles = makeStyles((theme) => ({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const [professionalsList, setProfessionalsList] = useState();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const { identity, loading } = useGetIdentity();

    useEffect(() => {
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements)
            }
        )
        getLanguajes().then(
            res => {
                setLanguageList(res.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setWorkingCenterList(res.elements);
            }
        )
    }, []);
    const exporter = professionals => {
        // if (!isOpen) {
        //     setIsOpen(true);
        // }
        setIsOpen(false);
        let professionalList = professionals.map(post => {
            const { id, professionalUserId, isDeleted, cellPhone, homePhone, contracts, role, ...professionalElement } = post;
            // professionalElement.role = professionalElement.role.name;
            let languages = [...professionalElement.languagesId];
            professionalElement.languagesId = '';
            languages.forEach((language, index) => {
                let commaOrNot = index === languages.length - 1 ? '' : ' , ';
                professionalElement.languagesId += languageList.filter(lang => lang.id === language)[0].value + commaOrNot;
            })
            // let contracts = [...professionalElement.contracts];
            // professionalElement.contracts = '';
            // contracts.forEach((contract, index) => {
            //     let commaOrNot = index === contracts.length - 1 ? '' : ' , ';
            //     professionalElement.contracts += contract.contractNumber + commaOrNot;
            // })
            if (professionalElement.associatedWorkingCenterId) {
                let workingCenter = workingCenterList.filter(workingcenter => workingcenter.id === professionalElement.associatedWorkingCenterId);
                if (workingCenter && workingCenter.length > 0) {
                    professionalElement.associatedWorkingCenterId = workingCenter[0].value;
                }
            }
            if (professionalElement.professionalTypeId) {
                let professionalType = professionalTypes.filter(professionalType => professionalType.id === professionalElement.professionalTypeId);
                if (professionalType && professionalType.length > 0) {
                    professionalElement.professionalTypeId = translate('professionalTypeId.' + professionalType[0].value);
                }
            }

            if (contracts && contracts.length > 0) {
                
                let contractValues = contracts.filter(p => p?.isMain).map(contract => contract?.contractNumber);
                
                let contractString = contractValues.join(",");
                
                professionalElement.contracts = contractString;
            }

            return professionalElement;
        });
        professionalList = translateHeaders(professionalList);
        setProfessionalsList(professionalList);
        const xlsxBlob = arrayToXlsx(professionalList);
        saveAs(xlsxBlob, 'profesionales.xlsx');
    };

    const saveExcelFile = () => {
        const xlsxBlob = arrayToXlsx(professionalsList);
        saveAs(xlsxBlob, 'profesionales.xlsx');
    };

    // const savePdfFile = () => {
    //     const unit = "pt";
    //     const size = "A4"; // Use A1, A2, A3 or A4
    //     const orientation = "landscape"; // portrait or landscape

    //     const marginLeft = 40;
    //     const doc = new jsPDF(orientation, unit, size);

    //     doc.setFontSize(12);

    //     const title = "Profesionales";
    //     const headers = [["Nombre", "Apellidos", "Email", "NIF/NIE", "Idiomas", "Tipo Profesional", "Centro Trabajo"]];

    //     const data = professionalsList.map(elt => [elt['Nombre'], elt['Apellidos'], elt['Email'], elt['NIF/NIE'], elt['Idiomas'], elt['Tipo Profesional'], elt['Centro Trabajo']]);

    //     let content = {
    //         startY: 50,
    //         head: headers,
    //         body: data
    //     };

    //     doc.text(title, marginLeft, 40);
    //     doc.autoTable(content);
    //     doc.save("profesionales.pdf")
    // };

    const saveCsvFile = () => {
        jsonExport(professionalsList, {
            headers: ["Nombre", "Apellidos", "Contrato principal", "Email", "NIF/NIE", "Idiomas", "Tipo Profesional", "Centro Trabajo"] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'profesionales');
        });
    };

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        worksheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 },{ wch: 30 }, { wch: 10 }, { wch: 35 }, { wch: 24 }, { wch: 14 }];
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };
    const translateHeaders = (professionalsList) => {
        for (let i = 0; i < professionalsList.length; i++) {
            professionalsList[i]['Nombre'] = professionalsList[i]['name'];
            delete professionalsList[i]['name'];
            professionalsList[i]['Apellidos'] = professionalsList[i]['surnames'];
            delete professionalsList[i]['surnames'];
            professionalsList[i]['Contrato principal'] = professionalsList[i]['contracts'];
            delete professionalsList[i]['contracts'];
            professionalsList[i]['Email'] = professionalsList[i]['email'];
            delete professionalsList[i]['email'];
            professionalsList[i]['NIF/NIE'] = professionalsList[i]['identificationNumber'];
            delete professionalsList[i]['identificationNumber'];
            professionalsList[i]['Idiomas'] = professionalsList[i]['languagesId'];
            delete professionalsList[i]['languagesId'];
            professionalsList[i]['Tipo Profesional'] = professionalsList[i]['professionalTypeId'];
            delete professionalsList[i]['professionalTypeId'];
            professionalsList[i]['Centro Trabajo'] = professionalsList[i]['associatedWorkingCenterId'];
            delete professionalsList[i]['associatedWorkingCenterId'];
            delete professionalsList[i]['activeDate'];
            delete professionalsList[i]['leavingDate'];
            delete professionalsList[i]['active'];
            delete professionalsList[i]['mainContractId'];
            delete professionalsList[i]['contractLogoId'];
            delete professionalsList[i]['picture'];
            // professionalsList[i]['Contratos'] = professionalsList[i]['contracts'];
            // delete professionalsList[i]['contracts'];
            // professionalsList[i]['Role'] = professionalsList[i]['role'];
            // delete professionalsList[i]['role'];
        }
        return professionalsList;
    }

    if (!professionalTypes) return null

    if (loading && !identity) {
        return <div></div>;
    }
    return (
        // <div onClick={() => setIsOpen(false)}>
        <>
            <List bulkActionButtons={props.permissions.professional.delete ? <PostBulkActionButtonsPermision perm="professional.delete" /> : false} {...props}
                filters={<ProfessionalFilter />}
                exporter={identity.roles === "CONTRACTING_AUTHORITY" ? false : exporter}
                filterDefaultValues={{ active: "true", isMain:true,professionalTypeId: professionalTypes.filter(e => e.value !== 'ADMIN').map(e => e.id) }}>
                <Datagrid rowClick={props.permissions.professional.edit ? "edit" : "show"} isRowSelectable={record => props.permissions.professional.delete}>
                    <TextField label="Nombre" source="name" />
                    <TextField label="Apellidos" source="surnames" />
                    <FunctionField label="Contrato Principal" sortBy="Contracts.ContractNumber"                          
                        render={record => {
                            let arr = record?.contracts?.filter(p => p?.isMain)?.map(p => p?.contractNumber)
                            return arr;
                        }}
                    />
                    <EmailField label="Email" source="email" sortBy="User.Email" />
                    <TextField label="NIF/NIE" source="identificationNumber" sortBy="User.IdentificationNumber" />
                    {/* <TextField label="Teléfono Móvil" source="cellPhone" /> */}
                    {/* <TextField label="Teléfono Fijo" source="homePhone" /> */}
                    {/* <TextField label="Idioma" source="languages" /> */}
                    {/* <FunctionField label="Idioma" render={record => `${record.languagesId.join(', ')}`} /> */}
                    <LanguajeArrayField label="Idioma" source="languagesId" sortable={true} sortBy="Professional.Languages" />
                    {/* <TextField label="Tipo" source="type" /> */}
                    <ProfessionalTypeField label="Tipo Profesional" source="professionalTypeId" sortable={true} sortBy="ProfessionalType.Name" />
                    {/* <FunctionField label="Tipo Profesional" render={records => {
                    if(records.type === 'SOCIAL_WORKER') return 'Trabajador social'
                    else if(records.type === 'PSYCHOLOGIST') return 'Psicólogo'
                    else if(records.type === 'TASOC') return 'TASOC'
                    else if(records.type === 'THERAPIST') return 'Terapia ocupacional'
                }} /> */}
                    <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={true} sortBy="WorkingCenter.Name" />
                    <FunctionField label="Activo" render={record => record.active ? 'Si' : 'No'} />
                </Datagrid>
            </List>
            {isOpen && (
                <div style={{ position: 'fixed', top: '6rem', right: '6rem', zIndex: 1000 }}
                    id="tooltip"
                    className={classes.paper}
                >
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveExcelFile()}>Excel</MUIButton>
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveCsvFile()}>CSV</MUIButton>
                    {/* <MUIButton variant="outlined" color="secondary" onClick={() => savePdfFile()}>PDF</MUIButton> */}
                </div>
            )}
        </>
    )
};

export const ProfessionalEdit = props => (
    <Edit title={<ProfessionalTitle />} mutationMode="pessimistic" {...props} transform={transformProfessional}>
        {ProfessionalForm(props, 'edit')}
    </Edit>
);

export const ProfessionalCreate = props => (
    <Create mutationMode="pessimistic" {...props} transform={transformProfessional}>
        {ProfessionalForm(props)}
    </Create>
);

export const ProfessionalShow = props => (
    <Show title={<ProfessionalTitle />} {...props}>
        {ProfessionalShowForm(props)}
    </Show>
);

export const ProfessionalForm = (props, mode = 'create') => {
    const [workingCenter, setworkingCenter] = useState();
    const [languajeList, setlanguajeList] = useState()
    const [professionalTypes, setprofessionalTypes] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const translate = useTranslate();
    const [aappTechId, setAappTechId] = useState();
    const [logisticTechId, setLogisticTechId] = useState();
    
    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();
    const identity = useGetIdentity();

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'professional',
                    payload: { data: transformProfessional(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4002) {
                    notify(error.error.message, 'error')
                    return {
                        password: error.error.message
                    };
                }
                if (error.error && error.error.code === 4003) {
                    notify(error.error.message, 'error')
                    return {
                        identificationNumber: error.error.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        // eslint-disable-next-line
        [mutate, mode, notify, props.basePath, redirect],
    );

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
                setAappTechId(res.elements.filter(e => e.value === 'AAPP_TECHNICIAN')[0]?.id);
                setLogisticTechId(res.elements.filter(e => e.value === 'LOGISTICS_TECHNICIAN')[0]?.id);
            }
        )
    }, [])

    
    const handleRejected = (rejectedFile) => {
        notify('Seleccione un tipo de archivo válido (de tipo imagen y con un tamaño máximo de 5 megas). Si guarda ahora la fotografía anterior será borrada', 'error', { autoHideDuration: 12000 });
    };

    
    const someCustomCondition = (scopedFormData) => {
        return scopedFormData?.isMain; 
    };

    return (
        <TabbedForm {...props} classes={classes} toolbar={<CustomToolbarPermissions perm="activities.delete" />} save={save}
            validate={mode === 'create' ? validateProfessionalCreation : validateProfessionalEdit}>
            <FormTab label="Profesional" contentClassName="tab-container">
                <ImageInput source="picture" label="Foto del profesional" accept="image/*" multiple={false} maxSize={5242880} options={{ onDropRejected: handleRejected }}>
                            <ImageField source="src" title="title" />
                </ImageInput>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                    <TextInput label="Nombre" source="name" />
                    <TextInput label="Apellidos" source="surnames" />
                    <TextInput label="Email" source="email" validate={[email()]} />
                    <TextInput label="NIF/NIE" source="identificationNumber" />
                </div>
                {
                    professionalTypes ?
                        <SelectInput label="Tipo de Profesional" source="professionalTypeId"
                            optionText={choice => translate('professionalTypeId.' + choice.value)}
                            optionValue="id"
                            choices={professionalTypes.filter(e => e.value !== 'ADMIN' && e.value !== 'SUPER_ADMIN')}
                        />
                        : null
                }
                {< ProfessionalRoleInput aappTechId={aappTechId} logisticTechId={logisticTechId} />}
                {
                    mode === 'create' ? <TextInput label="Contraseña" source="password" /> : null
                }
                <div className="new-line">
                    <DateInput label="Fecha de Alta" source="activeDate" disabled={identity?.identity?.roles !== "ADMIN" && identity?.identity?.roles !== "SUPER_ADMIN"} />
                    <DateInput label="Fecha de Baja" source="leavingDate" disabled={identity?.identity?.roles !== "ADMIN" && identity?.identity?.roles !== "SUPER_ADMIN"} />
                </div>
            </FormTab>
            <FormTab label="Contacto" contentClassName="tab-container">
                <TextInput label="Teléfono Móvil" source="cellPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} />
                <TextInput label="Teléfono fijo" source="homePhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} />
            </FormTab>
            <FormTab label="Misceláneo" contentClassName="tab-container">
                {
                    languajeList ?
                        <AutocompleteArrayInput
                            label="Idioma"
                            source="languagesId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
            </FormTab>
            <FormTab label="Contratos" contentClassName="tab-container no-label">
                <ArrayInput label="Contratos" source="contracts" id="arrayCustomProfessional" validate={required()}>
                    <SimpleFormIterator>
                        <FormDataConsumer 
                        >
                            {({ scopedFormData, getSource }) => {

                                const isDisabledForCurrent = someCustomCondition(scopedFormData);
                                const source = getSource('');
                                const indexMatch = source.match(/\[(\d+)\]/); // Captura el número entre corchetes

                                const index = indexMatch ? indexMatch[1] : null; // Obtenemos el índice o null si no se encuentra
                                
                                return (
                                    <>
                                        <div>
                                            <AutoCompleteCustom 
                                            type={"CONTRACTS"} 
                                            label={"Contrato"} 
                                            placeholder={"Buscar contrato"} 
                                            disabled={isDisabledForCurrent} 
                                            data={scopedFormData} 
                                            index={index} 
                                            requiredInput={true}
                                            {...props} />

                                            {scopedFormData?.id && (
                                                <div>
                                                    <BooleanInputCustom contractId={scopedFormData.id} source="isMain" {...props} isDisabled={false} />
                                                    <TextInput style={{ display: 'none' }} source="mainContractId" />
                                                </div>
                                            )}
                                        </div>

                                    </>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput style={{ display: 'none' }} source="contractsValidation" />
            </FormTab>
            {
                mode !== 'create' && (identity?.roles !== "SUPER_ADMIN" && identity?.roles !== "ADMIN") ?
                    <FormTab label="Calendario">

                        <div style={{ display: "flex", height: "100%", width: "100%", gap: "1rem" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h3>Agenda de <TextField source="name" style={{ fontSize: 18, fontWeight: "bold" }}></TextField></h3>
                                    <CalendarAddNewButtons></CalendarAddNewButtons>
                                </div>
                                <SmallCalendarCustom {...props} identity={identity} isNextMonth={false} isProfesional={true} />
                                <SmallCalendarCustom {...props} identity={identity} isNextMonth={true} isProfesional={true} />
                            </div>
                            <BigCalendarCustom {...props} isProfesional={true} />
                        </div>
                    </FormTab>
                    : null
            }
        </TabbedForm>
    )
}
const ProfessionalRoleInput = (props) => {
    const { values } = useFormState();
    const [firstTime, setFirstTime] = useState(true);
    const [professionalRoles, setprofessionalRoles] = useState();
    const [professionalTypes, setprofessionalTypes] = useState();
    const form = useForm();
    useEffect(() => {
        if (!firstTime) {
            if (values.professionalTypeId === professionalTypes.filter(x => x.value === "AAPP_TECHNICIAN")[0]?.id) {
                form.change('role', professionalRoles.filter(x => x.name === "AAPP_TECHNICIAN")[0]);
            } else if (values.professionalTypeId === professionalTypes.filter(x => x.value === "LOGISTICS_TECHNICIAN")[0]?.id) {
                form.change('role', professionalRoles.filter(x => x.name === "LOGISTICS_TECHNICIAN")[0]);
            } else {
                form.change('role', null);
            }
        }
        setFirstTime(false);
        // eslint-disable-next-line
    }, [values.professionalTypeId]);

    useEffect(() => {
        getProfessionalRoles().then(
            res => {
                setprofessionalRoles(res);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
            }
        )
    }, [])
    if (!values || values?.professionalTypeId === props?.aappTechId || values?.professionalTypeId === props?.logisticTechId) { return null; }
    return (
        professionalRoles ?
            <SelectInput
                label="Rol"
                source="role.id"
                choices={professionalRoles.filter(r => (r.name !== "AAPP_TECHNICIAN" && r.name !== "LOGISTICS_TECHNICIAN"))}
                validate={[required()]}
                optionText={record => 'professionalRole.' + record.name}
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
            />
            : null
    )
}

export const ProfessionalShowForm = (props, mode = 'show') => {
    const [workingCenter, setworkingCenter] = useState();
    const [languajeList, setlanguajeList] = useState()
    const [professionalRoles, setprofessionalRoles] = useState()
    const [professionalType, setprofessionalType] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const translate = useTranslate();
    const [aappTechId, setAappTechId] = useState();
    const [logisticTechId, setLogisticTechId] = useState();
    const identity = useGetIdentity();

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getProfessionalRoles().then(
            res => {
                setprofessionalRoles(res)
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalType(res.elements);
                setAappTechId(res.elements.filter(e => e.value === 'AAPP_TECHNICIAN')[0]?.id);
                setLogisticTechId(res.elements.filter(e => e.value === 'LOGISTICS_TECHNICIAN')[0]?.id);
            }
        )
    }, [])
    return (
        <TabbedForm classes={classes} toolbar={<CustomToolbarPermissions perm="professional.delete" />} validate={mode === 'create' ? validateProfessionalCreation : validateProfessionalEdit}>
            <FormTab label="Profesional" contentClassName="tab-container" >
                <TextInput label="Nombre" source="name" disabled />
                <TextInput label="Apellidos" source="surnames" disabled />
                <TextInput label="Email" source="email" validate={[email()]} disabled />
                <TextInput label="NIF/NIE" source="identificationNumber" disabled />
                {
                    professionalType ?
                        <SelectInput label="Tipo de Profesional" source="professionalTypeId" disabled
                            optionText={choice => translate('professionalTypeId.' + choice.value)}
                            optionValue="id"
                            choices={professionalType.filter(e => e.value !== 'ADMIN' && e.value !== 'SUPER_ADMIN')}
                        />
                        : null
                }
                {
                    professionalRoles ? <ProfessionalRoleShow aappTechId={aappTechId} logisticTechId={logisticTechId} />
                        : null
                }
                {
                    mode === 'create' ? <TextInput label="Contraseña" source="password" /> : null
                }
                <div className="new-line">
                    <DateInput label="Fecha de Alta" source="activeDate" disabled={true} />
                    <DateInput label="Fecha de Baja" source="leavingDate" disabled={true} />
                </div>
            </FormTab>
            <FormTab label="Contacto" contentClassName="tab-container">
                <TextInput label="Teléfono Móvil" source="cellPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled />
                <TextInput label="Teléfono fijo" source="homePhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled />
            </FormTab>
            <FormTab label="Misceláneo" contentClassName="tab-container">
                {
                    languajeList ?
                        <AutocompleteArrayInput
                            label="Idioma"
                            source="languagesId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                            disabled
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled
                        />
                        : null
                }
            </FormTab>
            <FormTab label="Contratos" contentClassName="tab-container no-label">
                <ArrayInput label="Contratos" source="contracts" id="arrayCustomProfessional" disabled>
                    <SimpleFormIterator>
                        
                        <ReferenceInput perPage={100} label="Contrato" source="id" reference="contracts" filterToQuery={(value) => {
                        return { contractNumber: value }
                        }}>
                            <AutocompleteInput
                                optionText="contractNumber"
                                validate={[required()]}
                            />
                         </ReferenceInput>
                         <FormDataConsumer>
                            {({
                                scopedFormData
                            }) =>
                                (
                                    scopedFormData?.id ? <BooleanInputCustom contractId={scopedFormData.id} source="isMain" {...props} isDisabled={true}/> : null
                                )
                            }
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput style={{ display: 'none' }} source="contractsValidation" />
            </FormTab>
            {
                mode !== 'create' && (identity?.roles !== "SUPER_ADMIN" && identity?.roles !== "ADMIN") ?
                    <FormTab label="Calendario">

                        <div style={{ display: "flex", height: "100%", width: "100%", gap: "1rem" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h3>Agenda de <TextField source="name" style={{ fontSize: 18, fontWeight: "bold" }}></TextField></h3>
                                    <CalendarAddNewButtons></CalendarAddNewButtons>
                                </div>
                                <SmallCalendarCustom {...props} identity={identity} isNextMonth={false} isProfesional={true}/>
                                <SmallCalendarCustom {...props} identity={identity} isNextMonth={true} isProfesional={true}/>
                            </div>
                            <BigCalendarCustom {...props} isProfesional={true} />
                        </div>
                    </FormTab>
                    : null
            }
        </TabbedForm>
    )
}


const CalendarAddNewButtons = (props) => {
    const formState = useFormState();
    const history = useHistory()
    const [open, setOpen] = useState(false)

    const newRecordHandler = () => {
        
        localStorage.removeItem('recordProfessional')
        localStorage.removeItem('recordParticipant')
        
        localStorage.setItem('recordProfessional', formState.values.id)
        setOpen(true)
        // history.push('/records/create')
    }
    const newActivityHandler = () => {
        history.push('/activities/create')
    }
    return (
        <>
            <div>
                <MUIButton
                    className="button-new-record"
                    label=""
                    disabled={false}
                    onClick={newRecordHandler}
                >
                    <RegistroIcon></RegistroIcon> <span>Nuevo Registro</span>
                </MUIButton>
            </div>
            
            <button onClick={newActivityHandler}
                style={{
                    background: 'none', color: 'black',
                    border: 'none', padding: 0, cursor: 'pointer', textAlign: 'left',
                    display: 'flex', alignItems: 'center'
                }}>
                <PostIcon></PostIcon> <span>Nueva Actividad</span>
            </button>
            
                
            <RecordsFormDialog 
                    {...props}
                    open={open} 
                    onClose={() => setOpen(false)} 
                    onSaved={() => {
                        setOpen(false)
                    }} 
                    isModal={true}
                    mode={'create'}
                    resource="records"
                    isRecord={true}
                    // id={record?.activityId+ ""}
                />
        </>
    )
}


const ProfessionalRoleShow = (props) => {
    const { values } = useFormState()
    const [professionalRoles, setprofessionalRoles] = useState()
    useEffect(() => {
        getProfessionalRoles().then(
            res => {
                setprofessionalRoles(res)
            }
        )
    }, [])
    if (!values || values?.professionalTypeId === props.aappTechId || values?.professionalTypeId === props.logisticTechId) return null
    return (
        professionalRoles ?
            <SelectInput
                label="Rol"
                source="role.id"
                choices={professionalRoles}
                validate={[required()]}
                optionText={record => 'professionalRole.' + record.name}
                optionValue="id"
                options={{ InputProps: { autoComplete: 'off' } }}
                disabled
            />
            : null
    )
}

const transformProfessional = (data) => {
    const activeDate = data.activeDate ? moment(data.activeDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const leavingDate = data.leavingDate ? moment(data.leavingDate).format('yyyy-MM-DDTHH:mm:ss') : null;

    return {
        ...data,
        roleId: data.role.id,
        contracts: data.contracts?.map(e => e.id),
        activeDate,
        leavingDate,
    }
}