
import { useGetIdentity, usePermissions } from "ra-core";
import { Fragment, useEffect, useState } from "react";
import { FunctionField, TextField, EmailField, Filter, TextInput} from "ra-ui-materialui";
import contractsProvider from "../provider/contracts.provider";
import {
    useNotify,
} from "react-admin";
import '../resources/resources.css';
import MUIButton from '@material-ui/core/Button';
import ContentSave from '@material-ui/icons/Save';
import { useListContext, List, Datagrid, Pagination} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


export const UserAndProfessionalForm = (props, mode = 'create') => {
    const { permissions } = usePermissions(); 
    const [loadingList, setLoadingList] = useState(true);
    const { identity, loading } = useGetIdentity();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingList(false);
        }, 3000); // Retraso de 3 segundos antes de mostrar la lista

        return () => clearTimeout(timer); //Limpia el timeout si el componente se desmonta
    }, []);

    if ((loading && !identity) || !permissions) {
        return <div></div>;
    }


    if (loadingList) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '34rem' }}>
            <CircularProgress />
        </div>;
    }


    return (
        <> 
            <List className="custom-list" {...props}
                bulkActionButtons={<CustomUserAndProfessionalToolbarPermissions props={props}/>}
                hasCreate={false}
                filterDefaultValues={{ isMapperAll: false, active:  props?.type === 'professional' ? "true": "ACTIVE", notInContract: props?.mode === 'add' ? true : false, contractId: props?.id+ "" }}
                sort={{ field: 'Name', order: 'DESC' }}
                exporter={false}
                filters={<UserAndProfessionalFilter />}
                pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}>
                <Datagrid rowClick={"toggleSelection"}   /* isRowSelectable={record => !((record?.contracts || []).some(contract => contract?.id === Number(props?.id)))} */>
                    <TextField label="Id" source="id" />
                    <TextField label="Nombre" source="name" />
                    <TextField label="Apellidos" source="surnames" />
                    <TextField label="NIF/NIE" source="identificationNumber" sortBy="User.IdentificationNumber" />
                    <EmailField label="Email" source="email" sortBy="User.Email" />
                    {
                        props?.type === 'professional' ? <FunctionField label="Activo" render={record => record.active ? 'Si' : 'No'} /> :
                        <FunctionField label="Estado" sortBy="Active" render={record => record?.active === 'ACTIVE' ?
                            'Activo' : record?.active === 'INACTIVE' ?
                                'Inactivo':
                                'Baja'
                        } />
                    }
                </Datagrid>
            </List >
        </>
    )
}


const UserAndProfessionalFilter = (props) => {

    return (
        <Filter {...props} style={{ "marginTop": 0 + "px" }}>
            <TextInput label="NIF/NIE" source="identificationNumber" alwaysOn />
            <TextInput label="Nombre" source="name" alwaysOn />
        </Filter>
    )
};

export const CustomUserAndProfessionalToolbarPermissions = props => {
    //const permissions = usePermissions()
    const { selectedIds, onUnselectItems } = useListContext();
    const notify = useNotify()
    const { onClose, handleUpdateProfessionals, handleUpdateUsers, mode, type } = props?.props;
    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => setOpen(true);
    const handleCloseDialog = () => setOpen(false);
    
    const handleConfirmSave = () => {
        handleCloseDialog();
        mode === 'delete' ? handleDeleteUserAndProfessional() : handleAddUserAndProfessional(); // Ejecuta la función solo si se confirma
    };

  useEffect(() => {
    if (!props?.props?.open) {
        onUnselectItems(); // Limpiar selección cuando se cierre el diálogo
    }
    // eslint-disable-next-line
  }, [props?.props?.open]);


    const handleAddUserAndProfessional = () => {

        const data = {
            id: props?.props?.id,
            isProfessional: type === 'professional'
        };

        // Agregar solo la propiedad correspondiente
        if (type === 'professional') {
            data.professionalsId = selectedIds;
        } else {
            data.usersId = selectedIds;
        }

        contractsProvider.addUserAndProfessional("", { data })
            .then(response => {

                onClose();
                type === 'professional' ? handleUpdateProfessionals() : handleUpdateUsers();
                notify('Elementos guardados', 'info');
            })
            .catch(error => {
                console.error("Error:", error);
                onClose();
                notify(error?.error?.message, 'error')
            });
    };

    const handleDeleteUserAndProfessional = () => {
    

        const data = {
            id: props?.props?.id,
            isProfessional: type === 'professional'
        };

        // Agregar solo la propiedad correspondiente
        if (type === 'professional') {
            data.professionalsId = selectedIds;
        } else {
            data.usersId = selectedIds;
        }

        contractsProvider.deleteUserAndProfessional("", { data })
            .then(response => {

                onClose();
                type === 'professional' ? handleUpdateProfessionals() : handleUpdateUsers();
                notify('Elementos eliminados', 'info');
            })
            .catch(error => {
                console.error("Error:", error);
                onClose();
                notify(error?.error?.message, 'error')
            });
    }

    //if (!permissions) return
    return (
        <Fragment>
            <MUIButton
                className={mode === 'delete' ? "button-delete":"button-save"}
                label=""
                disabled={false}
                onClick={handleOpenDialog}
            >
                <ContentSave className="icon-save"></ContentSave> <span>{mode === 'delete' ? 'Eliminar' : 'Añadir'}</span>
            </MUIButton>

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Confirmar acción</DialogTitle>
                <DialogContent>
                    ¿Estás seguro de que deseas guardar los cambios?
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleCloseDialog} color="secondary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleConfirmSave} color="primary">
                        Aceptar
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </Fragment>

    )
};