import { ActivityClient } from '../services/ohl.virtualCenter.api';
import moment from 'moment';

const apiClient = new ActivityClient(process.env.REACT_APP_PUBLIC_API);

class ActivitiesProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        
        if(params.filter && params.filter.q) params.filter.id = params.filter.q;


        params.filter.date = (params.filter.date)?moment(params.filter.date).format('yyyy-MM-DDTHH:mm:ss'):null
        params.filter.endDate = (params.filter.endDate)?moment(params.filter.endDate).format('yyyy-MM-DDTHH:mm:ss'):null

        if (params.filter.date && params.filter.endDate){
            if (moment(params.filter.endDate).isBefore(params.filter.date))
                return Promise.reject('La fecha hasta no puede ser anterior a la fecha desde')
        }else if (!params.filter.date && params.filter.endDate){
            return Promise.reject('La fecha desde no puede estar vacía si se indica la fecha hasta')
        }

        params.filter.isRecord = false

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            data.elements = data.elements.map(
                e => ({
                    ...e,
                    //participants: e.participants.map(e => e.id)
                })
            )
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        return apiClient.activityGet(params.id).then((data) => {
            return { data }
        });
    }

    update = (resource, params) => {
        return apiClient.activityPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.activityPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.activityDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new ActivitiesProvider();