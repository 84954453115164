import moment from "moment";
import { SimpleFormIterator, FormTab, Datagrid, AutocompleteInput, ReferenceInput, List, ArrayInput, TextField, Edit, TextInput, Filter, Create, Button, DeleteButton } from "ra-ui-materialui";
import { useState, useCallback } from "react";
//import { getContractingAuthorityType, } from "../services/master-tables.service";
import { useLocation } from 'react-router';
import {
    useRefresh,
    useNotify,
    useRedirect,
    useMutation,
    useCreate,
    useUpdate,
    TabbedForm,
    email
} from "react-admin";
import { TabsUseStyles } from "../App";
import { PostBulkActionButtonsPermision, CustomToolbarPermissions } from "./utils";
import { required } from "ra-core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ActionDelete from '@material-ui/icons/Delete';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import CloseIcon from '@material-ui/icons/Close';

const validateContractingAuthority = (values) => {
    const errors = {};
    if (!values.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values.email?.trim()) {
        errors.email = 'Requerido';
    }
    if (!values.type) {
        errors.type = 'Requerido';
    }

    return errors
}

export const ContractingAuthorityList = props => (
    <List bulkActionButtons={props.permissions.contractingAuthorities.delete ? <PostBulkActionButtonsPermision perm="contractingAuthorities.delete" /> : false} exporter={false} filters={<ContractingAuthoritiesFilter />} {...props}>
        <Datagrid rowClick="edit" isRowSelectable={record => props.permissions.contractingAuthorities.delete}>
            <TextField label="Nombre" source="name" />
            <TextField label="Tipo" source="type" />
        </Datagrid>
    </List>
);

export const ContractingAuthorityEdit = (props) => {

    return (
        <Edit title={<ContractingAuthorityTitle />} mutationMode="pessimistic" {...props} transform={transformContractingAuthorityEdit}>
            {ContractingAuthorityForm(props, 'edit')}
        </Edit>
    )
};

const ContractingAuthorityTitle = ({ record }) => {
    return <span>Cliente {record ? `${record.name}` : ''}</span>;
};


export const ContractingAuthorityCreate = (props) => {

    return (
        <Create mutationMode="pessimistic" {...props}>
            {ContractingAuthorityForm(props)}
        </Create>
    )
};

const ContractingAuthorityForm = (props, mode = 'create') => {

    //const [contractingAuthorityTypes] = useState()

    /*useEffect(() => {
        getContractingAuthorityType().then(
            res => {
                setcontractingAuthorityTypes(res.elements);
            }
        )
    }, []);*/


    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    //const [contractingAuthorityType] = useState()

    const [mutate] = useMutation();
    const validateEmail = email();
    
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'contractingAuthorities',
                    payload: { data: mode === 'create' ? transformContractingAuthorityCreate(values) : transformContractingAuthorityEdit(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4002) {
                    notify(error.error.message, 'error')
                    return {
                        password: error.error.message
                    };
                }
                if (error.error && error.error.code === 4003) {
                    return {
                        name: error.error.message
                    };
                }
                if (error.error && error.error.code === 4001) {
                    return {
                        email: error.error.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    return (
        <TabbedForm classes={classes} save={save} validate={validateContractingAuthority} toolbar={<CustomToolbarPermissions perm="contractingAuthorities.delete" />}>
            <FormTab label="Datos Generales" contentClassName="tab-container">
                <TextInput label="Nombre" source="name" />
                <TextInput label="Tipo" source="type" />
                <TextInput label="Email" source="email" validate={validateEmail} />
                <ReferenceInput label="Administrador" source="adminId" reference="admins" validate={[required()]} filter={{ onlyAdmins: true }}>
                    <AutocompleteInput optionText={(record) => [record.name, record.surnames].join(' ')} resettable={true} />
                </ReferenceInput>
            </FormTab>
            {
                mode !== 'create' ?
                    <FormTab label="Contratos" contentClassName="tab-container no-label">
                        <ArrayInput label="Contratos" source="contracts">
                            <SimpleFormIterator>
                                <ReferenceInput perPage={100} label="Contrato" source="id" reference="contracts" filterToQuery={(value) => {
                                    return { contractNumber: value }
                                }}>
                                    <AutocompleteInput
                                        optionText="contractNumber"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <TextInput style={{ display: 'none' }} source="contractsValidation" />
                    </FormTab>
                    : null
            }
        </TabbedForm>
    )
}
const ContractingAuthoritiesFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput label="Nombre" source="name" alwaysOn />
            <TextInput label="Tipo" source="type" />
        </Filter>
    );
}

// const PostCreateToolbar = props => {
//     return (
//         <Toolbar {...props} >
//             <SaveButton
//                 label="Guardar"
//                 redirect={props.redirect}
//                 submitOnEnter={true}
//             />
//             <Button 
//                 label="Volver" 
//                 component={Link}
//                 to={{
//                     pathname: props.redirect,
//                     // Here we specify the initial record for the create view
//                 }}
//             ></Button>
//         </Toolbar>
//     )
// };

export const AddNewContractingAuthorityButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [create, { loading }] = useCreate('ContractingAuthority');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: { ...values, clientId: record.id } } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar cliente"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar clientes es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir cliente</DialogTitle>
                <DialogContent>
                    <ContractingAuthorityForm save={handleSubmit} redirect={location.pathname} mode="create" loading={loading} handleCloseClick={handleCloseClick}></ContractingAuthorityForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

export const DeleteContractingAuthorityButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar clientes es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="ContractingAuthority"
                    record={record}
                    redirect={location.pathname}
                    label="borrar cliente"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

export const EditContractingAuthorityButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [edit, { loading }] = useUpdate('ContractingAuthority');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { data: transformContractingAuthorityEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Editar cliente"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar clientes es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Editar cliente</DialogTitle>
                <DialogContent>
                    <ContractingAuthorityForm
                        save={handleSubmit}
                        redirect={location.pathname}
                        mode="create"
                        loading={loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                    ></ContractingAuthorityForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

const transformContractingAuthorityEdit = (data) => {
    const createTime = data.createTime ? moment(data.createTime).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        createTime,
        contracts: data.contracts?.map(e => e.id),
    }
}

const transformContractingAuthorityCreate = (data) => {
    return {
        ...data,
        contracts: data.contracts?.map(e => e.id),
    }
}