import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { UserAndProfessionalForm } from "./UserAndProfessionalForm";
/* import { Edit } from 'react-admin';
import { useLocation } from 'react-router'; */
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const UserAndProfessionalDialog = (props) => {
/*     const location = useLocation();
 */

    const handleCloseClick = () => {
        props.onClose();
    };


    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={handleCloseClick}
        >
            {
                <MuiDialogTitle>
                    {props?.type === 'professional' 
                        ? (props?.mode === 'delete' ? 'Eliminar profesionales' : 'Añadir profesionales') 
                        : (props?.mode === 'delete' ? 'Eliminar usuarios' : 'Añadir usuarios')
                        }
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseClick}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
            }
            <DialogContent
                style={{ height: '35rem', display: 'flex', flexDirection: 'column' }}>
                {/* <Edit {...props} mutationMode="pessimistic" resource={props?.resource} type={props?.type} id={props.id} basePath={location?.pathname} >
                    
                </Edit> */}
                {UserAndProfessionalForm(props, props.mode)}
            </DialogContent>
        </Dialog>
    )
}