import { useRecordContext } from 'react-admin';
import { useEffect, useState } from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import {AutocompleteArrayInput} from "ra-ui-materialui";
import { ChipArrayUseStyles } from "../App";


export const CustomMultiSelect = props => {
    const {valuesList} = props;
    const record = useRecordContext();
    const [values, setValues] = useState([]);
    const chipClasses = ChipArrayUseStyles();

    useEffect(() => {
        let localValues = [];




        if(record?.isDeleted){
            record[props?.property].forEach((property) => {
                const likeValue = props?.valuesList?.find((e) => e?.id === property)?.value;
                if (likeValue) {
                    localValues?.push(likeValue);
                }
            });
        }
        
        setValues(localValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        {
            record?.isDeleted ? (
                <FormControl style={{ backgroundColor: "#0000000a", height: "2.8rem", marginTop: "0.5rem" }}>
                    <InputLabel id="multi-select-chips-label" style={{ fontFamily: "FS Matthew", paddingTop: "0.4rem", paddingLeft: "0.6rem" }}>{props.label}</InputLabel>
                    <Select
                        labelId="multi-select-chips-label"
                        id="multi-select-chips"
                        multiple
                        disabled
                        value={values}
                        renderValue={(selected) => (
                            <div>
                                {selected?.map((value) => (
                                    <Chip key={value} label={value} style={{ margin: '0.2rem' }} />
                                ))}
                            </div>
                        )}
                    >
                        {values?.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
            ) : valuesList ? <AutocompleteArrayInput
            label={props.label}
            source={props?.property}
            choices={valuesList}
            optionText="value"
            optionValue="id"
            options={{ InputProps: { autoComplete: 'off' } }}
            className="full"
            classes={chipClasses}/> : null
        }
        
        </>
        
    )

}